<template>
  <div>
    <a-table
      class="table-cbt"
      :columns="columns"
      :data-source="dataTable"
      :loading="loadingTable"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <div slot="participants" slot-scope="text, record">
        <a-tag v-if="record.id_level" color="blue">{{record.level.nama}}</a-tag>
        <template v-else>
          <a-tag class="m-2" v-for="kelas in record.pengawas_cbts" :key="kelas.id" color="orange">{{kelas.kela.level.nama}} - {{kelas.kela.simbol}}</a-tag>
        </template>
      </div>
      <a-table
        slot="expandedRowRender"
        slot-scope="text"
        :columns="innerColumns"
        :data-source="text.metadata"
        :pagination="false"
      >
    </a-table>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment'
const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    scopedSlots: { customRender: 'date' },
    align: 'center',
  },
  {
    title: 'CBT',
    align: 'center',
    children: [
      {
        title: 'Subject',
        dataIndex: 'subject',
        align: 'center',
      },
      {
        title: 'Participants',
        dataIndex: 'participants',
        scopedSlots: { customRender: 'participants' },
        align: 'center',
      },
    ],
  },
  {
    title: 'Updated by',
    dataIndex: 'updatedBy',
    scopedSlots: { customRender: 'updatedBy' },
    align: 'center',
  },
]
const innerColumns = [
  {
    title: 'Class',
    dataIndex: 'class',
    scopedSlots: { customRender: 'class' },
  },
  {
    title: 'Student Name',
    dataIndex: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Grades',
    align: 'center',
    children: [
      {
        title: 'Old Grade',
        dataIndex: 'old_grade',
        align: 'center',
      },
      {
        title: 'New Grade',
        dataIndex: 'new_grade',
        align: 'center',
      },
    ],
  },
  {
    title: 'Reason',
    dataIndex: 'notes',
    scopedSlots: { customRender: 'notes' },
  },
]
export default {
  data() {
    return {
      columns,
      innerColumns,
      dataTable: [],
      pagination: { current: 1 },
      loadingTable: false,
      type: '',
      sortBy: 'id',
      order: 'ASC',
      id_tahun_ajaran: null,
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // let order = ''
      // let sortBy = ''
      // if (sorter.field === 'MataPelajaran') {
      //   sortBy = 'nama'
      // }
      // if (sorter.order === 'ascend') {
      //   order = 'ASC'
      // } else {
      //   order = 'DESC'
      // }
      this.fetchDataTable()
    },
    async fetchDataTable() {
      try {
        this.loadingTable = true
        const res = await this.$store.dispatch('cbt/FETCH_CBT_LOG', { page: this.pagination.current, order: this.order, search: this.search, sortBy: this.sortBy })
        this.loadingTable = false
        const pagination = { ...this.pagination }
        pagination.total = res.total
        this.pagination = pagination
        this.dataTable = res.log.map(row => {
          const metadata = row.metadata.map((meta, i) => {
            return {
              ...meta,
              key: i + 1,
            }
          })
          const cbt = row.cbt
          console.log(cbt)
          return {
            ...row,
            ...cbt,
            metadata,
            key: row.id,
            date: moment(row.updatedAt).locale('id').format('dddd, DD MMMM YYYY HH:mm:ss'),
            subject: row.cbt.mata_pelajaran.nama,
            level: row.cbt.tingkat,
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
  mounted() {
    this.fetchDataTable()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style>

</style>
